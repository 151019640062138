import { AsyncPipe, NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  output,
} from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatProgressBar } from '@angular/material/progress-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { map, of, switchMap } from 'rxjs';

import { FileInputComponent, FileItem, NewFile } from './file-input.component';

@Component({
  selector: 'app-file-input-item',
  templateUrl: './file-input-item.component.html',
  styleUrls: ['./file-input-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, MatIcon, MatIconButton, MatProgressBar, AsyncPipe],
})
export class FileInputItemComponent {
  private domSanitizer = inject(DomSanitizer);
  readonly fileInput = inject(FileInputComponent);

  item = input.required<FileItem>();
  private item$ = toObservable(this.item);

  preview$ = this.item$.pipe(
    switchMap((item) =>
      item instanceof NewFile ? item.preview$ : of(item.preview),
    ),
    map((url) =>
      url ? this.domSanitizer.bypassSecurityTrustUrl(url) : undefined,
    ),
  );

  uploadError$ = this.item$.pipe(
    switchMap((item) =>
      item instanceof NewFile
        ? item.uploadError$.asObservable()
        : of(undefined),
    ),
  );

  uploadProgress$ = this.item$.pipe(
    switchMap((item) =>
      item instanceof NewFile
        ? item.uploadProgress$.asObservable()
        : of(undefined),
    ),
  );

  readonly retryUpload = output<void>();
  readonly remove = output<void>();
}
