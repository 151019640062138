import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { from, Observable, switchMap } from 'rxjs';

import { AuthService } from './auth.service';

export function authInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> {
  const auth = inject(AuthService);

  return from(auth.getSession()).pipe(
    switchMap((session) =>
      next(
        session
          ? req.clone({
              headers: req.headers.append(
                'Authorization',
                `Bearer ${session.token}`,
              ),
            })
          : req,
      ),
    ),
  );
}
