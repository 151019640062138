import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { fromEvent, shareReplay } from 'rxjs';

import { AuthService } from '../auth/auth.service';

const baseUrl = 'https://chat.boundstate.co';
const websiteToken = 'jZx4s4vfpA9taWpiSPtwqzz5';

@Injectable({ providedIn: 'root' })
export class ChatService {
  private auth = inject(AuthService);
  private document = inject(DOCUMENT);

  readonly ready$ = fromEvent(window, 'chatwoot:ready').pipe(shareReplay(1));

  load() {
    this.ready$.subscribe(() => {
      void this.updateChatUser();

      this.auth.signedIn$.subscribe(() => {
        void this.updateChatUser();
      });

      this.auth.signedOut$.subscribe(() => {
        window.$chatwoot.reset();
      });
    });

    window.chatwootSettings = {
      type: 'expanded_bubble',
      launcherTitle: 'Support',
    };

    const script = document.createElement('script');
    script.src = `${baseUrl}/packs/js/sdk.js`;
    script.onload = () => window.chatwootSDK.run({ baseUrl, websiteToken });
    this.document.body.append(script);
  }

  private async updateChatUser() {
    const session = await this.auth.getSession();

    if (session) {
      window.$chatwoot.setUser(session.claims.userId, {
        identifier_hash: session.claims.chatwootHmac,
        email: session.claims.email,
        name: session.claims.name,
      });
    }
  }
}
