import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatAnchor, MatIconAnchor } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';

import { PresentationService } from './presentation.service';

@Component({
  selector: 'app-presentation-toggle',
  templateUrl: './presentation-toggle.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatAnchor, MatTooltip, MatIcon, MatIconAnchor, AsyncPipe],
})
export class PresentationToggleComponent {
  private presentationService = inject(PresentationService);

  readonly value$ = this.presentationService.value$;

  toggle(): void {
    void this.presentationService.toggle();
  }
}
