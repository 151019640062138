<h1 mat-dialog-title>Confirm email address</h1>

<form [formGroup]="form" (submit)="submit()">
  <div mat-dialog-content class="flex flex-col gap-4">
    <div>Enter the code that was emailed to you:</div>

    <mat-form-field>
      <mat-label>Code</mat-label>
      <input matInput formControlName="code" />
      <mat-error appFormFieldError />
    </mat-form-field>

    <app-form-group-error />
  </div>

  <div mat-dialog-actions>
    <button type="button" mat-button (click)="resendEmailConfirmationCode()">
      Resend code
    </button>

    <div class="grow"></div>

    <button type="button" mat-button mat-dialog-close>Cancel</button>
    <button type="submit" mat-button color="primary" [disabled]="form.invalid">
      Verify
    </button>
  </div>
</form>
