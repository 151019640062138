import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';

export type Theme = 'dark' | 'light';

const STORAGE_KEY = 'theme';

@Injectable({ providedIn: 'root' })
export class ThemeService {
  private document = inject(DOCUMENT);

  setTheme(theme: Theme | undefined) {
    if (theme !== this._theme) {
      this._theme = theme;
      this.applyTheme();
      this.saveTheme();
    }
  }
  get theme() {
    return this._theme;
  }
  private _theme?: Theme;

  load() {
    this._theme = (localStorage.getItem(STORAGE_KEY) ?? undefined) as
      | Theme
      | undefined;
    this.applyTheme();
  }

  private saveTheme() {
    if (this.theme) {
      localStorage.setItem(STORAGE_KEY, this.theme);
    } else {
      localStorage.removeItem(STORAGE_KEY);
    }
  }

  private applyTheme() {
    for (const theme of ['dark', 'light']) {
      this.document.body.classList.toggle(
        `${theme}-mode`,
        this.theme === theme,
      );
    }
  }
}
