import { inject, Pipe, PipeTransform } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map, startWith } from 'rxjs';

@Pipe({ name: 'orgLink' })
export class OrgLinkPipe implements PipeTransform {
  private router = inject(Router);

  transform(orgId: string) {
    return this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      startWith({ url: this.router.url }),
      map(({ url }) => `/${orgId}/${getUrlAfterOrgId(url)}`),
    );
  }
}

function getUrlAfterOrgId(url: string) {
  // URL to a specific shop does not work across orgs
  if (url.includes('shops/')) {
    return '';
  }
  // don't include query parameters, which are typically specific to org
  return url.split('/').slice(2).join('/').split('?')[0];
}
