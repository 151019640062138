<button mat-icon-button [matMenuTriggerFor]="menu" #trigger="matMenuTrigger">
  <mat-icon>account_circle</mat-icon>
</button>
<mat-menu #menu="matMenu" xPosition="before">
  <button type="button" mat-menu-item (click)="openAccountDialog()">
    <mat-icon matMenuItemIcon>account_box</mat-icon>
    {{ session().claims.name }}
  </button>

  @if (session().claims.role === 'orgOwner') {
    @if (orgStore.selectedEntity(); as org) {
      <button type='button' mat-menu-item (click)='editOrg.emit(org)'>
        <mat-icon matMenuItemIcon>domain</mat-icon>
        {{ org.name }}
      </button>
    }
  }

  <button type="button" mat-menu-item (click)="openChangePasswordDialog()">
    <mat-icon matMenuItemIcon>lock</mat-icon>
    Change password
  </button>
  <button type="button" mat-menu-item [matMenuTriggerFor]="themeMenu">
    <mat-icon matMenuItemIcon>palette</mat-icon>
    Appearance
  </button>
  <mat-menu #themeMenu="matMenu">
    @for (option of themeOptions; track option.theme) {
      <button
        type="button"
        mat-menu-item
        [class.bg-primary]="theme.theme === option.theme"
        [class.text-primary]="theme.theme === option.theme"
        (click)="theme.setTheme(option.theme)"
      >
        <mat-icon matMenuItemIcon>{{ option.icon }}</mat-icon>
        {{ option.label }}
      </button>
    }
  </mat-menu>

  <mat-divider />

  @if (session().claims.role === 'admin') {
    @for (org of orgStore.entities(); track org.id) {
      <a
        mat-menu-item
        class="group with-actions"
        [class.bg-primary]="orgId() === org.id"
        [class.text-primary]="orgId() === org.id"
        [routerLink]="org.id | orgLink | async"
      >
        <mat-icon matMenuItemIcon>domain</mat-icon>

        <span class="truncate">{{ org.name }}</span>

        <div class="ms-auto invisible group-hover:visible">
          <button
            mat-icon-button
            class="opacity-60 hover:opacity-100"
            (click)="
                    $event.preventDefault();
                    $event.stopPropagation();
                    trigger.closeMenu();
                    editOrg.emit(org)
                  "
          >
            <mat-icon>settings</mat-icon>
          </button>
        </div>
      </a>
    } @empty {
      @if (orgStore.isPending()) {
        <app-list-placeholder [lines]="4" />
      }
    }

    <button mat-menu-item (click)='addOrg.emit()'>
      <mat-icon matMenuItemIcon>add_circle</mat-icon>
      Add org
    </button>
    <mat-divider />
  }

  <button mat-menu-item (click)="signOut()">
    <mat-icon matMenuItemIcon>logout</mat-icon>
    Sign out
  </button>
</mat-menu>
