<div
  class="file-list"
  [class.file-list--crop-preview]="cropPreview()"
  [class.file-list--invalid]="errorState"
  [ngClass]="['file-list--appearance-' + appearance()]"
>
  @for (item of items(); track $index) {
    <app-file-input-item
      [item]="item"
      (remove)="remove(item)"
      (retryUpload)="retryUpload($any(item))"
    />
  }

  @if (items().length < maxFiles()) {
    <app-file-input-placeholder
      [label]="label()"
      (click)="fileInput.click()"
      (addFiles)="addFiles($event)"
    />
  }
</div>

@if (errorMessage()) {
    <div class="error-message">
      {{ errorMessage() }}
    </div>
}

<input
  #fileInput
  type="file"
  hidden
  [multiple]="maxFiles() - items().length > 1"
  [disabled]="maxFiles() - items().length === 0"
  [accept]="fileTypes()"
  (change)="addFiles($any($event.target).files)"
/>
