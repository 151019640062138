<form [formGroup]="form" (submit)="save()">
  <h1 mat-dialog-title>Account</h1>

  <div mat-dialog-content class="flex flex-col gap-4">
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput type="text" formControlName="name" />
      <mat-error appFormFieldError />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Email</mat-label>
      <input type="email" matInput formControlName="email" />
      <mat-error appFormFieldError />
    </mat-form-field>
    <app-form-group-error />
  </div>

  <div mat-dialog-actions align="end">
    <button type="button" mat-button mat-dialog-close>Cancel</button>
    <button type="submit" mat-button [disabled]="form.invalid || !form.dirty">
      Save
    </button>
  </div>
</form>
