import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
} from '@angular/core';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButton } from '@angular/material/button';
import {
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { firstValueFrom } from 'rxjs';

import { UserService } from '../admin/users/user.service';
import { UserStore } from '../admin/users/user.store';
import { AuthService } from '../auth/auth.service';
import { ErrorService } from '../shared/error/error.service';
import { FormFieldErrorDirective } from '../shared/form/form-field-error.directive';
import { FormGroupErrorComponent } from '../shared/form/form-group-error.component';
import { ProgressService } from '../shared/progress/progress.service';

@Component({
  templateUrl: './account.dialog.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    MatError,
    FormFieldErrorDirective,
    FormGroupErrorComponent,
    MatButton,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogTitle,
  ],
})
export class AccountDialog {
  private auth = inject(AuthService);
  private cd = inject(ChangeDetectorRef);
  private dialogRef = inject(MatDialogRef);
  private errors = inject(ErrorService);
  private fb = inject(FormBuilder);
  private progress = inject(ProgressService);
  private snackBar = inject(MatSnackBar);

  private userService = inject(UserService);
  private userStore = inject(UserStore);

  static open(dialog: MatDialog): MatDialogRef<AccountDialog> {
    return dialog.open<AccountDialog>(AccountDialog, {
      disableClose: true,
      width: '400px',
    });
  }

  readonly form = this.fb.nonNullable.group({
    name: [this.auth.session().claims.name, Validators.required],
    email: [
      this.auth.session().claims.email,
      [Validators.required, Validators.email],
    ],
  });

  async save() {
    if (this.form.invalid) {
      return;
    }

    try {
      await this.progress.wrap(this.updateAccount());

      this.dialogRef.close();

      this.snackBar.open('Account updated');
    } catch (error) {
      // show any server-side errors
      this.errors.handleFormError(this.form, error);
      this.cd.markForCheck();
    }
  }

  private async updateAccount() {
    const formValue = this.form.getRawValue();

    await firstValueFrom(this.userService.updateAccount(formValue));

    await this.auth.getSession({ forceRefresh: true });

    // optimistically update store
    const { claims } = this.auth.session();
    this.userStore.updateUser({
      id: claims.userId,
      orgId: claims.orgId,
      ...formValue,
    });
  }
}
