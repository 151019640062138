<table mat-table [dataSource]="shops$" [appTablePlaceholder]="(shops$ | async) === null" class="w-full">
  <ng-container matColumnDef="shop">
    <th mat-header-cell *matHeaderCellDef>Shop</th>
    <td mat-cell *matCellDef="let shop">
      {{ shop.name }}
    </td>
  </ng-container>

  <ng-container matColumnDef="alias">
    <th mat-header-cell *matHeaderCellDef>Alias</th>
    <td mat-cell *matCellDef="let shop">
      {{ shop.name | shopAlias }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: columns"></tr>
</table>
