import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  input,
  OnInit,
  output,
  signal,
} from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatAnchor, MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatError, MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { RouterLink } from '@angular/router';

import { PASSWORD_MIN_LENGTH } from '../../constants';
import { getErrorMessage } from '../../shared/error/get-error-message';
import { FormFieldErrorDirective } from '../../shared/form/form-field-error.directive';
import { FormGroupErrorComponent } from '../../shared/form/form-group-error.component';
import { ProgressService } from '../../shared/progress/progress.service';
import { AuthService } from '../auth.service';
import { AuthHeaderComponent } from '../header/auth-header.component';
import { LegalLinksComponent } from '../legal-links/legal-links.component';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatCheckbox,
    ReactiveFormsModule,
    MatButton,
    MatFormField,
    MatInput,
    FormGroupErrorComponent,
    FormFieldErrorDirective,
    MatError,
    MatProgressSpinner,
    MatAnchor,
    RouterLink,
    AuthHeaderComponent,
    LegalLinksComponent,
  ],
})
export class SignUpComponent implements OnInit {
  private auth = inject(AuthService);
  private cd = inject(ChangeDetectorRef);
  private fb = inject(FormBuilder);
  private progress = inject(ProgressService);

  readonly form = this.fb.nonNullable.group({
    password: [
      '',
      [Validators.required, Validators.minLength(PASSWORD_MIN_LENGTH)],
    ],
    agree: [false, Validators.requiredTrue],
  });

  code = input.required<string>();
  email = input.required<string>();

  loading = signal(true);
  errorMessage = signal<string | undefined>(undefined);

  afterSignUp = output();

  ngOnInit() {
    void this.signIn();
  }

  async submit() {
    if (this.form.invalid) {
      return;
    }

    const formValue = this.form.getRawValue();
    try {
      await this.progress.wrap(this.auth.confirmSignIn(formValue.password));
      this.afterSignUp.emit();
    } catch (error) {
      this.form.controls.password.setErrors({ auth: error });

      this.cd.markForCheck();
    }
  }

  private async signIn() {
    // sign out first in case a user is already signed in
    await this.auth.signOut();

    // automatically sign in using the temporary password to make sure it's valid
    // (the next step should be to set a new password)
    try {
      const result = await this.auth.signIn(this.email(), this.code());
      if (result.isSignedIn) {
        this.afterSignUp.emit();
      } else if (
        result.nextStep.signInStep !==
        'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED'
      ) {
        this.errorMessage.set('Unexpected state');
      }
    } catch (error) {
      this.errorMessage.set(
        error instanceof Error && error.name === 'NotAuthorizedException'
          ? 'Invalid or expired sign up code'
          : getErrorMessage(error),
      );
    } finally {
      this.loading.set(false);
    }
  }
}
