import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
} from '@angular/core';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButton } from '@angular/material/button';
import {
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';

import { AuthService } from '../../auth/auth.service';
import { FormFieldErrorDirective } from '../../shared/form/form-field-error.directive';
import { FormGroupErrorComponent } from '../../shared/form/form-group-error.component';
import { ProgressService } from '../../shared/progress/progress.service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './confirm-email.dialog.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatDialogTitle,
    FormsModule,
    ReactiveFormsModule,
    MatDialogContent,
    MatFormField,
    MatLabel,
    MatInput,
    MatError,
    FormFieldErrorDirective,
    FormGroupErrorComponent,
    MatDialogActions,
    MatButton,
    MatDialogClose,
  ],
})
export class ConfirmEmailDialog {
  private auth = inject(AuthService);
  private cd = inject(ChangeDetectorRef);
  private dialogRef = inject<MatDialogRef<void, boolean>>(MatDialogRef);
  private fb = inject(FormBuilder);
  private progress = inject(ProgressService);
  private snackBar = inject(MatSnackBar);

  static open(dialog: MatDialog): MatDialogRef<ConfirmEmailDialog> {
    return dialog.open<ConfirmEmailDialog, void, boolean>(ConfirmEmailDialog, {
      disableClose: true,
      width: '400px',
    });
  }

  readonly form = this.fb.nonNullable.group({
    code: ['', Validators.required],
  });

  async resendEmailConfirmationCode() {
    await this.progress.wrap(this.auth.resendEmailConfirmationCode());

    this.snackBar.open('Please check your email');
  }

  async submit() {
    const { code } = this.form.getRawValue();

    try {
      await this.progress.wrap(this.auth.confirmEmail(code));
      this.dialogRef.close(true);
    } catch (error) {
      this.form.controls.code.setErrors({
        auth: error,
      });
      this.cd.markForCheck();
    }
  }
}
