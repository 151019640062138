@if (signUpParams(); as signUpParams) {
  <app-sign-up
    [code]="signUpParams.code"
    [email]="signUpParams.email"
    (afterSignUp)="afterSignUp()"
  />
} @else if (showAuthenticator()) {
  <amplify-authenticator
    [loginMechanisms]="loginMechanisms"
    [formFields]="formFields"
    [services]="services"
  >
    <ng-template amplifySlot="header">
      <app-auth-header />
    </ng-template>

    <ng-template amplifySlot="confirm-reset-password-header">
      Please enter the code that was emailed to you:
    </ng-template>

    <ng-template amplifySlot="sign-in-footer">
      <div class="sign-in-footer">
        <button
          mat-button
          class="w-full font-normal"
          (click)="authenticator.toForgotPassword()"
        >
          Forgot your password?
        </button>

        <div class="text-body-sm text-secondary">
          By continuing, you agree to our
          <app-legal-links />
        </div>
      </div>
    </ng-template>

    <ng-template amplifySlot="authenticated">
      <router-outlet />
    </ng-template>
  </amplify-authenticator>
} @else {
  <router-outlet />
}
