<div
  class="flex flex-col min-h-dvh pb-28"
  [class.print-layout]="printModeActive$ | async"
>
  @if (!session().claims.emailVerified) {
    <button
      class="w-full px-4 py-2 bg-primary text-primary text-center text-body-md font-bold"
      mat-ripple
      (click)="openConfirmEmailDialog()"
    >
      Please confirm your email
    </button>
  }

  @if ((printModeActive$ | async) === false) {
    <mat-toolbar class="bg-surface-container gap-2">
      @if (orgStore.selectedEntity(); as org) {
        <a class="me-auto outline-none" [routerLink]="['/', org.id]">
          @if (org.logoUrl) {
            <img [src]="org.logoUrl" class="h-10 rounded-xs" [alt]="org.name" />
          } @else {
            {{ org.name }}
          }
        </a>
      }
      <div class="flex-grow"></div>
      @if (session() | canUser: 'viewOrgAnalysis') {
        <a mat-button routerLink="./analysis" routerLinkActive="bg-secondary/10"
          >Analysis</a
        >
        @if (session() | canUser: 'viewShops') {
          <a mat-button routerLink="./shops" routerLinkActive="bg-secondary/10"
            >Shop</a
          >
        }
      }
      @if (session() | canUser: 'editUsers') {
        <a
          mat-button
          routerLink="./admin/users"
          routerLinkActive="bg-secondary/10"
          >Users</a
        >
        <a
          mat-button
          routerLink="./admin/shops"
          routerLinkActive="bg-secondary/10"
          >Shops</a
        >
      }
      @if (session() | canUser: 'viewOrgAnalysis') {
        <app-presentation-toggle />
      }

      <app-user-menu
        [orgId]="orgId()"
        (addOrg)="addOrg()"
        (editOrg)="editOrg($event)"
      />
    </mat-toolbar>
  }

  <router-outlet />
</div>
