import { isDevMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import * as Sentry from '@sentry/angular';
import { Amplify } from 'aws-amplify';
import { I18n as AmplifyI18n } from 'aws-amplify/utils';

import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';

Sentry.init({
  dsn: 'https://16328d016d9a3735507044d2428720a2@o366870.ingest.sentry.io/4505941846065152',
  environment: import.meta.env.NG_APP_STAGE,
  enabled: !isDevMode(),
});

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: import.meta.env.NG_APP_AUTH_USER_POOL_ID,
      identityPoolId: import.meta.env.NG_APP_AUTH_IDENTITY_POOL_ID,
      userPoolClientId: import.meta.env.NG_APP_AUTH_USER_POOL_CLIENT_ID,
    },
  },
});

AmplifyI18n.putVocabulariesForLanguage('en', {
  // this is more clear in case legacy user exists and can be migrated, but legacy password was entered incorrectly
  'User does not exist.': 'Incorrect email or password.',
  'Incorrect username or password.': 'Incorrect email or password.',
  'Username/client id combination not found.': 'Email not found.',
});

bootstrapApplication(AppComponent, appConfig)
  // eslint-disable-next-line no-console
  .catch((err) => console.error(err));
