<div
  class="file"
  [class.file--invalid]="fileInput.errorState"
  [class.file--with-preview]="preview$ | async"
  [class.file--crop-preview]="fileInput.cropPreview()"
  [class.file--round]="fileInput.round()"
  [ngClass]="['file--appearance-' + fileInput.appearance()]"
>
  @if (preview$ | async; as preview) {
    <div class="file__preview">
      <img [src]="preview" />
    </div>
  } @else {
    <div class="file__icon">
      <mat-icon>insert_drive_file</mat-icon>
    </div>
  }
  <div class="file__info" [class.file__info--error]="uploadError$ | async">
    <div class="file__name">{{ item().name }}</div>
    @if (uploadError$ | async; as uploadError) {
      <div class="file__error">
        {{ uploadError.message }}
      </div>
    }
  </div>
  <div class="file__actions">
    @if (uploadError$ | async) {
      <button
        type="button"
        mat-icon-button
        (click)="$event.stopPropagation(); retryUpload.emit()"
      >
        <mat-icon>refresh</mat-icon>
      </button>
    }
    <button
      type="button"
      mat-icon-button
      (click)="$event.stopPropagation(); remove.emit()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
  @if (uploadProgress$ | async; as uploadProgress) {
    @if (uploadProgress < 100) {
      <mat-progress-bar [value]="uploadProgress" />
    }
  }
</div>
