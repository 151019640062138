import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { DomainService } from '../../shared/domain.service';
import { ImgFallbackDirective } from '../../shared/img-fallback.directive';

@Component({
  selector: 'app-auth-header',
  templateUrl: './auth-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ImgFallbackDirective],
})
export class AuthHeaderComponent {
  private domainService = inject(DomainService);

  readonly defaultLogoUrl = '/assets/brand.svg';

  readonly customLogoUrl = this.domainService.getLogoUrl();
}
