import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  output,
} from '@angular/core';
import { MatRipple } from '@angular/material/core';
import { MatIcon } from '@angular/material/icon';

import { FileDragDropDirective } from './file-drag-drop.directive';
import { FileInputComponent } from './file-input.component';

@Component({
  selector: 'app-file-input-placeholder',
  templateUrl: './file-input-placeholder.component.html',
  styleUrls: ['./file-input-placeholder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatRipple, FileDragDropDirective, NgClass, MatIcon],
})
export class FileInputPlaceholderComponent {
  readonly fileInput = inject(FileInputComponent);

  label = input('');

  readonly addFiles = output<File[]>();
}
