import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  output,
} from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { Router, RouterLink } from '@angular/router';

import { Org } from '@rpm/shared/schemas';

import { AccountDialog } from '../../account/account.dialog';
import { ChangePasswordDialog } from '../../account/change-password/change-password.dialog';
import { AuthService } from '../../auth/auth.service';
import { OrgStore } from '../../org/org.store';
import { ListPlaceholderComponent } from '../list-placeholder/list-placeholder.component';
import { OrgLinkPipe } from '../org-link.pipe';
import { Theme, ThemeService } from '../theme.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatDivider,
    MatMenu,
    MatIcon,
    MatIconButton,
    MatMenuTrigger,
    MatMenuItem,
    AsyncPipe,
    ListPlaceholderComponent,
    OrgLinkPipe,
    RouterLink,
  ],
})
export class UserMenuComponent {
  private auth = inject(AuthService);
  private dialog = inject(MatDialog);
  private router = inject(Router);

  readonly theme = inject(ThemeService);
  readonly orgStore = inject(OrgStore);

  orgId = input.required<string>();

  addOrg = output();
  editOrg = output<Org>();

  readonly themeOptions: Array<{
    label: string;
    icon: string;
    theme: Theme | undefined;
  }> = [
    { label: 'System', icon: 'contrast', theme: undefined },
    { label: 'Light', icon: 'light_mode', theme: 'light' },
    { label: 'Dark', icon: 'dark_mode', theme: 'dark' },
  ];

  session = this.auth.session;

  openAccountDialog() {
    AccountDialog.open(this.dialog);
  }

  openChangePasswordDialog() {
    ChangePasswordDialog.open(this.dialog);
  }

  async signOut() {
    await this.auth.signOut();
    void this.router.navigate(['/']);
  }
}
