<div
  class="file"
  [class.file--invalid]="fileInput.errorState"
  [class.file--round]="fileInput.round()"
  [ngClass]="['file--appearance-' + fileInput.appearance()]"
  matRipple
  appFileDragDrop
  (droppedFiles)="addFiles.emit($event)"
>
  <div class="file__icon">
    <mat-icon>upload</mat-icon>
  </div>
  <div class="file__info">
    <div class="file__label">{{ label() }}</div>
  </div>
</div>
