import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import { MatAnchor } from '@angular/material/button';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { isString } from 'radashi';

import { AppTitleService } from '../shared/app-title.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatAnchor, RouterLink],
})
export class NotFoundComponent implements OnInit {
  private activatedRoute = inject(ActivatedRoute);
  private appTitle = inject(AppTitleService);
  private location = inject(Location);

  ngOnInit(): void {
    this.appTitle.setTitle('404');

    const replaceUrl: unknown = this.activatedRoute.snapshot.queryParams['url'];
    if (isString(replaceUrl)) {
      this.location.replaceState(replaceUrl);
    }
  }
}
