<form [formGroup]="form" (submit)="save()">
  <div mat-dialog-title>{{ org ? 'Edit' : 'Create' }} Org</div>

  <div mat-dialog-content class="flex flex-col gap-4">
    <app-file-input
      label="Add a logo"
      formControlName="logo"
      fileTypes="image/*"
    />

    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" />
      <mat-error appFormFieldError />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Custom Domain</mat-label>
      <input matInput formControlName="slug" />
      <span matTextSuffix>.{{ siteHost }}</span>
      <mat-error appFormFieldError />
    </mat-form-field>

    <!--    <app-help-->
    <!--      *ngIf="form.controls.customDomain.value && form.controls.customDomain.dirty"-->
    <!--    >-->
    <!--      Requires a CNAME record aliasing<br />-->
    <!--      <code>{{ form.controls.customDomain.value }}</code> to-->
    <!--      <code>{{ siteDomain }}</code>-->
    <!--    </app-help>-->

    <app-form-group-error />
  </div>

  <div mat-dialog-actions align="end">
    <button type="button" mat-button mat-dialog-close>Cancel</button>
    <button type="submit" mat-button color="primary" [disabled]="form.invalid">
      Save
    </button>
  </div>
</form>
