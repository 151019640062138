import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
} from '@angular/core';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButton } from '@angular/material/button';
import {
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';

import { AuthService } from '../../auth/auth.service';
import { PASSWORD_MIN_LENGTH } from '../../constants';
import { ErrorService } from '../../shared/error/error.service';
import { FormFieldErrorDirective } from '../../shared/form/form-field-error.directive';
import { FormGroupErrorComponent } from '../../shared/form/form-group-error.component';
import { ProgressService } from '../../shared/progress/progress.service';

@Component({
  templateUrl: './change-password.dialog.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    MatError,
    FormFieldErrorDirective,
    FormGroupErrorComponent,
    MatButton,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
  ],
})
export class ChangePasswordDialog {
  private auth = inject(AuthService);
  private cd = inject(ChangeDetectorRef);
  private dialogRef = inject(MatDialogRef);
  private errors = inject(ErrorService);
  private fb = inject(FormBuilder);
  private progress = inject(ProgressService);
  private snackBar = inject(MatSnackBar);

  static open(dialog: MatDialog): MatDialogRef<ChangePasswordDialog> {
    return dialog.open<ChangePasswordDialog>(ChangePasswordDialog, {
      disableClose: true,
      width: '400px',
    });
  }

  readonly form = this.fb.nonNullable.group({
    currentPassword: ['', Validators.required],
    newPassword: [
      '',
      [Validators.required, Validators.minLength(PASSWORD_MIN_LENGTH)],
    ],
  });

  async save() {
    if (this.form.invalid) {
      return;
    }

    const { currentPassword, newPassword } = this.form.getRawValue();

    try {
      await this.progress.wrap(
        this.auth.changePassword(currentPassword, newPassword),
      );

      this.dialogRef.close();
      this.snackBar.open('Password changed');
    } catch (error) {
      if (error instanceof Error) {
        switch (error.name) {
          case 'NotAuthorizedException':
            this.form.controls.currentPassword.setErrors({
              incorrectPassword: true,
            });
            break;
          case 'InvalidPasswordException':
            this.form.controls.newPassword.setErrors({ auth: error });
            break;
          default:
            this.form.controls.currentPassword.setErrors({ auth: error });
        }
      } else {
        this.errors.handleActionError('changing password', error);
      }
      this.cd.markForCheck();
    }
  }
}
