import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  OnInit,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from './auth/auth.service';
import { PermissionService } from './auth/permission.service';
import { ProgressService } from './shared/progress/progress.service';

/**
 * Component used to do redirects,
 * because Angular route [redirect functions](https://angular.dev/api/router/RedirectFunction)
 * can't return a promise, and we need to ensure the session is loaded to determine the correct path.
 */
@Component({
  selector: 'app-home',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RedirectComponent implements OnInit {
  private auth = inject(AuthService);
  private permissions = inject(PermissionService);
  private progress = inject(ProgressService);
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private snackBar = inject(MatSnackBar);

  orgId = input<string>();

  ngOnInit() {
    const emailConfirmationCode =
      this.route.snapshot.queryParamMap.get('confirmEmail');
    if (emailConfirmationCode) {
      void this.confirmEmail(emailConfirmationCode);
    }

    void this.redirect();
  }

  private async confirmEmail(code: string) {
    await this.progress.wrap(this.auth.confirmEmail(code));
    this.snackBar.open('Email address confirmed');
  }

  private async redirect() {
    const { returnTo } = this.route.snapshot.queryParams;
    if (typeof returnTo === 'string') {
      void this.router.navigateByUrl(returnTo, { replaceUrl: true });
    } else {
      const session = await this.auth.getSession();
      let path: string[];
      if (session) {
        const orgId = this.orgId() ?? this.auth.session().claims.orgId;

        if (this.permissions.canUser('viewOrgAnalysis')) {
          path = [orgId, 'analysis'];
        } else {
          const shopIds = Object.keys(session.claims.shops ?? {});
          path = shopIds.length ? [orgId, 'shops', shopIds[0]] : [orgId, '404'];
        }

        void this.router.navigate(path, { replaceUrl: true });
      }
    }
  }
}
