import {
  Directive,
  ElementRef,
  HostListener,
  inject,
  input,
} from '@angular/core';

@Directive({
  selector: 'img[appImgFallback]',
})
export class ImgFallbackDirective {
  private elementRef = inject<ElementRef<HTMLImageElement>>(ElementRef);

  fallback = input<string>(undefined, { alias: 'appImgFallback' });

  @HostListener('error')
  private onError() {
    const fallback = this.fallback();
    if (fallback && fallback !== this.elementRef.nativeElement.src) {
      this.elementRef.nativeElement.src = fallback;
    }
  }
}
