import {
  ChangeDetectorRef,
  Directive,
  HostBinding,
  HostListener,
  inject,
  OnDestroy,
  output,
} from '@angular/core';
import getDroppedFiles from '@uppy/utils/lib/getDroppedFiles';
import { delay, of, Subscription } from 'rxjs';

/**
 * Outputs files dropped on the element.
 *
 * Adapted from https://github.com/transloadit/uppy/blob/c1a152deafe6bd2d628b6f5c6ecec2ee0ad67795/packages/%40uppy/drag-drop/src/index.js
 */
@Directive({
  selector: '[appFileDragDrop]',
})
export class FileDragDropDirective implements OnDestroy {
  private cd = inject(ChangeDetectorRef);

  readonly droppedFiles = output<File[]>();

  @HostBinding('class.dragging-over')
  isDraggingOver = false;

  private removeDragOverClassSubscription?: Subscription;

  ngOnDestroy(): void {
    this.removeDragOverClassSubscription?.unsubscribe();
  }

  @HostListener('drop', ['$event'])
  async handleDrop(event: DragEvent): Promise<void> {
    event.preventDefault();
    event.stopPropagation();

    this.removeDragOverClassSubscription?.unsubscribe();

    this.isDraggingOver = false;

    if (event.dataTransfer) {
      const files = await getDroppedFiles(event.dataTransfer);
      this.droppedFiles.emit(files);
    }
  }

  @HostListener('dragover', ['$event'])
  handleDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();

    // 1. Add a small (+) icon on drop
    // (and prevent browsers from interpreting this as files being _moved_ into the browser, https://github.com/transloadit/uppy/issues/1978)
    if (event.dataTransfer) {
      event.dataTransfer.dropEffect = 'copy';
    }

    this.removeDragOverClassSubscription?.unsubscribe();
    this.isDraggingOver = true;
  }

  @HostListener('dragleave', ['$event'])
  handleDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();

    this.removeDragOverClassSubscription?.unsubscribe();
    // Timeout against flickering, this solution is taken from drag-drop library.
    // Solution with 'pointer-events: none' didn't work across browsers.
    this.removeDragOverClassSubscription = of(null)
      .pipe(delay(50))
      .subscribe(() => {
        this.isDraggingOver = false;
        this.cd.markForCheck();
      });
  }
}
