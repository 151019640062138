import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { map, of, shareReplay, switchMap } from 'rxjs';

import { AppTitleService } from '../shared/app-title.service';
import { TablePlaceholderDirective } from '../shared/table-placeholder/table-placeholder.directive';
import { ShopService } from '../shop/shop.service';

import { PresentationService } from './presentation.service';
import { ShopAliasPipe } from './shop-alias.pipe';

@Component({
  selector: 'app-presentation-legend',
  templateUrl: './presentation-legend.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatTableModule,
    ShopAliasPipe,
    AsyncPipe,
    TablePlaceholderDirective,
  ],
})
export class PresentationLegendComponent implements OnInit {
  private appTitle = inject(AppTitleService);
  private presentationService = inject(PresentationService);

  private shopService = inject(ShopService);

  readonly columns = ['alias', 'shop'];

  readonly shops$ = this.presentationService.value$.pipe(
    switchMap((config) =>
      config
        ? this.shopService
            .getAllInOrg(config.orgId)
            .pipe(
              map((shops) =>
                shops.filter((shop) => !shop.groups?.includes(config.group)),
              ),
            )
        : of([]),
    ),
    shareReplay(1),
  );

  ngOnInit(): void {
    this.appTitle.setTitle('Presentation Legend');
  }
}
