import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButton } from '@angular/material/button';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { AuthContext, AuthFormFields, LoginMechanism } from '@aws-amplify/ui';
import {
  AmplifyAuthenticatorModule,
  AuthenticatorService,
} from '@aws-amplify/ui-angular';
import { confirmResetPassword } from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import { map } from 'rxjs';

import { UserService } from './admin/users/user.service';
import { AuthService } from './auth/auth.service';
import { AuthHeaderComponent } from './auth/header/auth-header.component';
import { LegalLinksComponent } from './auth/legal-links/legal-links.component';
import { SignUpComponent } from './auth/sign-up/sign-up.component';
import { AppTitleService } from './shared/app-title.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  // `amplify-authenticator` doesn't work with push change detection
  changeDetection: ChangeDetectionStrategy.Default,
  imports: [
    AmplifyAuthenticatorModule,
    MatButton,
    RouterOutlet,
    SignUpComponent,
    AuthHeaderComponent,
    LegalLinksComponent,
  ],
})
export class AppComponent implements OnInit {
  readonly authenticator = inject(AuthenticatorService);

  private appTitle = inject(AppTitleService);
  private auth = inject(AuthService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private snackBar = inject(MatSnackBar);
  private userService = inject(UserService);

  readonly loginMechanisms: LoginMechanism[] = ['email'];

  readonly services: AuthContext['services'] = {
    // eslint-disable-next-line @typescript-eslint/require-await
    validateCustomSignUp: async (formData: Record<string, string>) => {
      if (!formData['agree']) {
        return { agree: '' }; // require agree to be checked but don't show error message
      }
      return undefined;
    },
    handleForgotPasswordSubmit: async (input) => {
      await confirmResetPassword(input);
      await this.auth.signIn(input.username, input.newPassword);

      this.snackBar.open('Password reset');
    },
  };

  readonly formFields: AuthFormFields = {
    signIn: {
      username: {
        labelHidden: true,
        placeholder: 'Email',
      },
      password: {
        labelHidden: true,
        placeholder: 'Password',
      },
    },
    forgotPassword: {
      username: {
        labelHidden: true,
      },
    },
  };

  showAuthenticator = signal(true);

  signUpParams = toSignal(
    this.route.queryParamMap.pipe(
      map((query) => {
        const code = query.get('signUpCode');
        const email = query.get('email');
        return code && email ? { code, email: atob(email) } : undefined;
      }),
    ),
  );

  ngOnInit() {
    Hub.listen('auth', ({ payload }) => {
      if (payload.event === 'signedIn') {
        // authenticator component doesn't update properly
        // if we sign in using the direct methods
        this.showAuthenticator.set(false);
      } else if (payload.event === 'signedOut') {
        this.showAuthenticator.set(true);
        this.appTitle.resetTitle();
      }
    });
  }

  afterSignUp() {
    this.userService.confirmAccount().subscribe();

    // remove sign in params from URL
    void this.router.navigate([], {
      queryParams: {},
      relativeTo: this.route,
      replaceUrl: true,
    });
  }
}
