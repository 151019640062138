import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
  input,
  untracked,
} from '@angular/core';
import { MatAnchor } from '@angular/material/button';
import { MatRipple } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatToolbar } from '@angular/material/toolbar';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';

import { Org } from '@rpm/shared/schemas';

import { ConfirmEmailDialog } from './account/confirm-email/confirm-email.dialog';
import { EditOrgDialog } from './admin/org/edit-org.dialog';
import { AuthService } from './auth/auth.service';
import { CanUserPipe } from './auth/can-user.pipe';
import { OrgStore } from './org/org.store';
import { PresentationToggleComponent } from './presentation/presentation-toggle.component';
import { PrintModeService } from './shared/print-mode.service';
import { UserMenuComponent } from './shared/user-menu/user-menu.component';

@Component({
  selector: 'app-layout',
  templateUrl: './app-layout.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatToolbar,
    RouterLink,
    MatAnchor,
    RouterLinkActive,
    PresentationToggleComponent,
    RouterOutlet,
    AsyncPipe,
    CanUserPipe,
    UserMenuComponent,
    MatRipple,
  ],
})
export class AppLayoutComponent {
  private auth = inject(AuthService);
  private dialog = inject(MatDialog);
  private printMode = inject(PrintModeService);

  readonly orgStore = inject(OrgStore);

  orgId = input.required<string>();

  session = this.auth.session;

  printModeActive$ = this.printMode.isActive$;

  constructor() {
    effect(() => {
      const orgId = this.orgId();
      untracked(() => {
        this.orgStore.selectOrg(orgId);
        this.orgStore.loadAll();
      });
    });
  }

  openConfirmEmailDialog() {
    ConfirmEmailDialog.open(this.dialog);
  }

  editOrg(org: Org) {
    EditOrgDialog.open(this.dialog, { org });
  }

  addOrg() {
    EditOrgDialog.open(this.dialog);
  }
}
