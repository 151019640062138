<div class="size-full flex flex-col items-center justify-center">
  @if (errorMessage(); as error) {
    <h1 class="text-headline-lg text-center font-brand mb-2">Whoops!</h1>
    <div class="mb-5">{{ error }}</div>
    <a mat-flat-button routerLink="/">Return home</a>
  } @else if (loading()) {
    <mat-spinner />
  } @else {
    <div class="w-[30rem] max-w-full bg-surface-container rounded-xl p-12">
      <app-auth-header class="block mb-5" />
      <form [formGroup]="form" class="flex flex-col gap-4" (submit)="submit()">
        <h1 class="text-headline-sm text-center font-brand">Sign Up</h1>

        <mat-form-field appearance="outline">
          <input matInput disabled [value]="email()" />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <input
            matInput
            placeholder="Password"
            type="password"
            formControlName="password"
          />
          <mat-error appFormFieldError />
        </mat-form-field>

        <mat-checkbox formControlName="agree">
          I agree to the
          <app-legal-links />
        </mat-checkbox>

        <app-form-group-error />

        <div class="flex flex-col gap-2">
          <button type="submit" mat-flat-button [disabled]="form.invalid">
            Sign up
          </button>

          <a mat-button routerLink="/"> Back to sign in </a>
        </div>
      </form>
    </div>
  }
</div>
