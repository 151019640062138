import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  DoCheck,
  effect,
  ElementRef,
  ErrorHandler,
  forwardRef,
  inject,
  Injector,
  input,
  isDevMode,
  OnDestroy,
  OnInit,
  signal,
  viewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormGroupDirective,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgControl,
  NgForm,
  UntypedFormControl,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import AwsS3 from '@uppy/aws-s3';
import { Uppy, UppyFile } from '@uppy/core';
import ThumbnailGenerator from '@uppy/thumbnail-generator';
import { noop } from 'radashi';
import { BehaviorSubject, firstValueFrom, ReplaySubject } from 'rxjs';

import { FileStorageService } from '../file-storage.service';

import { FileInputItemComponent } from './file-input-item.component';
import { FileInputPlaceholderComponent } from './file-input-placeholder.component';

export interface ExistingFile {
  type: 'existing';
  name: string;
  preview?: string;
}

export class NewFile {
  readonly preview$ = new BehaviorSubject<string | undefined>(undefined);
  readonly uploadProgress$ = new BehaviorSubject<number | undefined>(undefined);
  readonly uploadComplete$ = new ReplaySubject<void>(1);
  readonly uploadError$ = new BehaviorSubject<Error | undefined>(undefined);

  get name() {
    return this.uppyFile.name;
  }

  get key() {
    return this.uppyFile.meta.key;
  }

  constructor(readonly uppyFile: UppyFile<{ key?: string }>) {}
}

export type FileItem = ExistingFile | NewFile;

export type FileInputValue = FileItem[];

type FileTypes = string | string[] | null | undefined;

@Component({
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: FileInputComponent,
      multi: true,
    },
  ],
  imports: [NgClass, FileInputItemComponent, FileInputPlaceholderComponent],
})
export class FileInputComponent
  implements OnInit, OnDestroy, ControlValueAccessor, Validator, DoCheck
{
  private errorHandler = inject(ErrorHandler);
  private fileStorage = inject(FileStorageService);
  private defaultErrorStateMatcher = inject(ErrorStateMatcher);
  private cd = inject(ChangeDetectorRef);
  private injector = inject(Injector);
  private parentFormGroup = inject(FormGroupDirective, { optional: true });
  private parentForm = inject(NgForm, { optional: true });

  /** @internal */
  static nextId = 0;

  private fileInput =
    viewChild.required<ElementRef<HTMLInputElement>>('fileInput');

  cropPreview = input(false);

  round = input(false);

  label = input('');

  appearance = input<'standard' | 'grid' | 'list'>('standard');

  /**
   * Whether to start uploading automatically after the first file is selected.
   */
  autoProceed = input(true);

  /**
   * Max file size in bytes for each individual file.
   */
  maxFileSize = input<number | undefined>(10 * 1024 * 1024); // 10 MB

  /**
   * Array or comma separated list of mime types or file extensions.
   * e.g.: image/*,application/pdf,.psd
   */
  fileTypes = input<FileTypes>();

  /**
   * Maximum number of files.
   */
  maxFiles = input(1);

  errorStateMatcher = input<ErrorStateMatcher>();

  /** @internal */
  errorState = false;

  /** @internal */
  onChange: (value: FileInputValue) => void = noop;
  /** @internal */
  onTouched: () => void = noop;

  /** @internal */
  items = computed(() => [...this.existingFiles(), ...this.newFiles()]);

  errorMessage = signal<string | undefined>(undefined);

  private existingFiles = signal<ExistingFile[]>([]);
  private newFiles = signal<NewFile[]>([]);

  private uppy?: Uppy;

  constructor() {
    effect(() => {
      this.uppy?.setOptions({
        restrictions: {
          allowedFileTypes: normalizeFileTypes(this.fileTypes()),
          maxNumberOfFiles: this.maxFiles() - this.existingFiles().length,
        },
      });
    });
  }

  ngOnInit(): void {
    const uppy = new Uppy({
      id: `file-input-${FileInputComponent.nextId++}`,
      autoProceed: this.autoProceed(),
      debug: isDevMode(),
      restrictions: {
        allowedFileTypes: normalizeFileTypes(this.fileTypes()),
        maxNumberOfFiles: this.maxFiles() - this.existingFiles.length,
        maxFileSize: this.maxFileSize(),
      },
    });

    uppy.use(AwsS3, {
      getUploadParameters: async (file) => {
        const { key, url } = await firstValueFrom(
          this.fileStorage.getSignedUploadUrl({
            fileExtension: file.extension,
          })
        );

        file.meta['key'] = key;

        return { method: 'PUT', url };
      },
    });

    uppy.use(ThumbnailGenerator, {
      thumbnailType: 'image/png',
    });

    uppy.on('files-added', (files) => {
      this.newFiles.update((newFiles) => [
        ...newFiles,
        ...files.map((f) => new NewFile(f)),
      ]);

      this.onTouched();
      this.onChange(this.items());
      this.cd.markForCheck();
    });

    uppy.on('upload-retry', (fileId) => {
      const newFile = this.newFiles().find((f) => f.uppyFile.id === fileId);
      if (newFile) {
        newFile.uploadError$.next(undefined);
        this.onChange(this.items()); // for validation to apply
      }
    });

    uppy.on('upload-progress', (file, progress) => {
      if (file) {
        const newFile = this.newFiles().find((f) => f.uppyFile.id === file.id);
        if (newFile) {
          newFile.uploadProgress$.next(
            (progress.bytesUploaded / progress.bytesTotal) * 100,
          );
        }
      }
    });

    uppy.on('upload-success', (file) => {
      if (file) {
        const newFile = this.newFiles().find((f) => f.uppyFile.id === file.id);
        if (newFile) {
          newFile.uploadProgress$.next(100);
          newFile.uploadComplete$.next();
        }
      }
    });

    uppy.on('upload-error', (file, error) => {
      this.errorHandler.handleError(error);

      if (file) {
        const newFile = this.newFiles().find((f) => f.uppyFile.id === file.id);
        if (newFile) {
          newFile.uploadProgress$.next(undefined);
          newFile.uploadError$.next(error);
          this.onChange(this.items()); // for validation to apply
          this.cd.markForCheck();
        }
      }
    });

    uppy.on('thumbnail:generated', ({ id }, preview) => {
      const newFile = this.newFiles().find((f) => f.uppyFile.id === id);
      if (newFile) {
        newFile.preview$.next(preview);
      }
    });

    this.uppy = uppy;
  }

  ngDoCheck(): void {
    const parent = this.parentFormGroup || this.parentForm || null;
    const control = this.injector.get(NgControl, null)?.control || null;

    this.errorState =
      !!this.errorMessage() ||
      (this.errorStateMatcher() ?? this.defaultErrorStateMatcher).isErrorState(
        control as UntypedFormControl | null,
        parent,
      );
  }

  ngOnDestroy(): void {
    const { uppy } = this;
    if (uppy) {
      // if a file upload is still in progress, don't close uppy until it completes
      if (Object.keys(uppy.getState().currentUploads).length > 0) {
        uppy.on('complete', () => uppy.close());
        uppy.on('error', () => uppy.close());
      } else {
        uppy.close();
      }
    }
  }

  validate(): ValidationErrors | null {
    return this.newFiles().some((newFile) => !!newFile.uploadError$.value)
      ? { failedUpload: true }
      : null;
  }

  writeValue(value: Array<ExistingFile | string> | null): void {
    this.existingFiles.set(
      value?.map((v) =>
        typeof v === 'string' ? { type: 'existing', name: v } : v,
      ) ?? [],
    );
    this.newFiles.set([]);
    this.uppy?.cancelAll();
    this.cd.markForCheck();
  }

  registerOnChange(fn: (value: FileInputValue) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  addFiles(files: File[] | FileList): void {
    this.errorMessage.set(undefined);

    for (const file of Array.from(files)) {
      try {
        this.uppy?.addFile({
          source: 'file input',
          name: file.name,
          type: file.type,
          data: file,
        });
      } catch (error) {
        this.errorHandler.handleError(error);

        if (error instanceof Error) {
          this.errorMessage.set(error.message);
        }
      }
    }

    // clear file input value so file can be added again
    this.fileInput().nativeElement.value = '';
  }

  retryUpload(item: NewFile): void {
    void this.uppy?.retryUpload(item.uppyFile.id);
  }

  remove(item: FileItem): void {
    if (item instanceof NewFile) {
      this.uppy?.removeFile(item.uppyFile.id);
      this.newFiles.update((newFiles) => newFiles.filter((f) => f !== item));
    } else {
      this.existingFiles.update((existingFiles) =>
        existingFiles.filter((f) => f !== item),
      );
    }
    this.onTouched();
    this.onChange(this.items());
  }
}

/**
 * Converts an array or string of content types to
 * the format expected by Uppy.
 */
function normalizeFileTypes(types: FileTypes): string[] | null {
  if (!types) {
    return null;
  }

  return Array.isArray(types) ? types : types.split(',');
}
