import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { SignedUploadUrl, SignedUploadUrlBody } from '@rpm/shared/schemas';

const apiUrl = import.meta.env.NG_APP_API_URL;

@Injectable({ providedIn: 'root' })
export class FileStorageService {
  private http = inject(HttpClient);

  getSignedUploadUrl(body: SignedUploadUrlBody) {
    return this.http.post<SignedUploadUrl>(`${apiUrl}/signed-upload-url`, body);
  }
}
