<a
  href="https://rpmtraining.net/terms"
  class="text-primary hover:underline"
  target="_blank"
  >Terms of Use</a
>
and
<a
  href="https://rpmtraining.net/privacy"
  class="text-primary hover:underline"
  target="_blank"
  >Privacy Policy</a
>
