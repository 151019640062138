import { Routes } from '@angular/router';

import { AppLayoutComponent } from './app-layout.component';
import { resolveSession } from './auth/auth.service';
import { canUser } from './auth/permission.service';
import { NotFoundComponent } from './not-found/not-found.component';
import { PresentationLegendComponent } from './presentation/presentation-legend.component';
import { RedirectComponent } from './redirect.component';

export const routes: Routes = [
  { path: 'presentation-legend', component: PresentationLegendComponent },
  {
    path: '',
    pathMatch: 'full',
    component: RedirectComponent,
  },
  {
    path: ':orgId',
    component: AppLayoutComponent,
    // ensure session is loaded
    resolve: { session: resolveSession },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: RedirectComponent,
      },
      {
        path: 'shops',
        loadChildren: () => import('./shop/shop.routes'),
      },
      {
        path: 'analysis',
        canActivate: [canUser('viewOrgAnalysis')],
        loadChildren: () => import('./analyze-org/analyze-org.routes'),
      },
      {
        path: 'admin',
        canActivate: [canUser('viewAdmin')],
        loadChildren: () => import('./admin/admin.routes'),
      },
      { path: '404', component: NotFoundComponent },
      { path: '**', component: NotFoundComponent },
    ],
  },
  { path: '404', component: NotFoundComponent },
  { path: '**', component: NotFoundComponent },
];
