import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
} from '@angular/core';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButton } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import {
  MatError,
  MatFormField,
  MatLabel,
  MatSuffix,
} from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { firstValueFrom } from 'rxjs';

import { Org } from '@rpm/shared/schemas';

import { OrgService } from '../../org/org.service';
import { OrgStore } from '../../org/org.store';
import { DomainService } from '../../shared/domain.service';
import { ErrorService } from '../../shared/error/error.service';
import { FormFieldErrorDirective } from '../../shared/form/form-field-error.directive';
import { FormGroupErrorComponent } from '../../shared/form/form-group-error.component';
import { ProgressService } from '../../shared/progress/progress.service';
import {
  FileInputComponent,
  FileInputValue,
  NewFile,
} from '../file-input/file-input.component';

export interface EditOrgDialogData {
  org?: Org;
}

// const DOMAIN_PATTERN = /^([a-z\d-]{1,}(?<!-)\.)+[a-z]{2,}$/i;
const SUBDOMAIN_PATTERN = /^[a-z\d-]+$/i;

@Component({
  selector: 'app-edit-org-dialog',
  templateUrl: './edit-org.dialog.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatDialogTitle,
    FormsModule,
    ReactiveFormsModule,
    MatDialogContent,
    FileInputComponent,
    MatFormField,
    MatLabel,
    MatInput,
    MatError,
    FormFieldErrorDirective,
    MatSuffix,
    FormGroupErrorComponent,
    MatDialogActions,
    MatButton,
    MatDialogClose,
  ],
})
export class EditOrgDialog {
  private cd = inject(ChangeDetectorRef);
  private data = inject<EditOrgDialogData>(MAT_DIALOG_DATA);
  private dialogRef = inject<MatDialogRef<EditOrgDialogData>>(MatDialogRef);
  private domainService = inject(DomainService);
  private errors = inject(ErrorService);
  private fb = inject(FormBuilder);
  private progress = inject(ProgressService);
  private orgService = inject(OrgService);
  private orgStore = inject(OrgStore);
  private snackBar = inject(MatSnackBar);

  static open(
    dialog: MatDialog,
    data: EditOrgDialogData = {},
  ): MatDialogRef<EditOrgDialog, never> {
    return dialog.open<EditOrgDialog, EditOrgDialogData, never>(EditOrgDialog, {
      disableClose: true,
      width: '400px',
      data,
    });
  }

  readonly siteHost = this.domainService.siteHost;

  readonly form = this.fb.nonNullable.group({
    name: [this.org?.name ?? '', Validators.required],
    slug: [this.org?.slug ?? '', Validators.pattern(SUBDOMAIN_PATTERN)],
    logo: [
      (this.org?.logoUrl
        ? ([
            {
              type: 'existing',
              name: 'logo.png',
              preview: this.org.logoUrl,
            },
          ] satisfies FileInputValue)
        : []) as FileInputValue,
    ],
  });

  get org() {
    return this.data.org;
  }

  async save() {
    const args = this.getArgs();

    try {
      if (this.org) {
        const updatedOrg = await this.progress.wrap(
          firstValueFrom(
            this.orgService.updateOrg({ id: this.org.id, ...args }),
          ),
        );
        this.orgStore.setOrg(updatedOrg);
      } else {
        const newOrg = await this.progress.wrap(
          firstValueFrom(this.orgService.createOrg(args)),
        );
        this.orgStore.setOrg(newOrg);
      }

      this.dialogRef.close();
      this.snackBar.open(`Org ${this.org ? 'updated' : 'created'}`);
    } catch (error) {
      this.errors.handleFormError(this.form, error);
      this.cd.markForCheck();
    }
  }

  private getArgs() {
    const {
      logo: [logo],
      ...formValue
    } = this.form.getRawValue();

    let logoKey: string | undefined;

    if (!logo) {
      logoKey = ''; // delete from server
    } else if (logo instanceof NewFile) {
      logoKey = logo.key;
    }

    return { ...formValue, logoKey };
  }
}
